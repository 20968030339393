.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

/* Bottom right text */
.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.top-right {
    margin-top: 64px;
    position: absolute;
    top: 0;
    right: 0;
}

.top-left {
    margin-top: 64px;
    position: absolute;
    top: 0;
    left: 0;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.small-fit {
    max-width: 50%;
    max-height: 50vh;
    margin: auto;
}

.large-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover; 
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}